/**
 * @generated SignedSource<<9fb66e959069b2fb573a9eff1d3f25d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RichTextAlignment = "CENTER" | "LEFT" | "RIGHT";
import { FragmentRefs } from "relay-runtime";
export type RichTextBlock_block$data = {
  readonly jsonContent: {
    readonly json: string | null;
  };
  readonly textAlignment: RichTextAlignment;
  readonly " $fragmentType": "RichTextBlock_block";
};
export type RichTextBlock_block$key = {
  readonly " $data"?: RichTextBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"RichTextBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RichTextBlock_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textAlignment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Json",
      "kind": "LinkedField",
      "name": "jsonContent",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "json",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RichTextBlock",
  "abstractKey": null
};

(node as any).hash = "89d923f98e7620de64567a65542000eb";

export default node;
